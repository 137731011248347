export const logo = ['1407 305', `
  <title>expresslogo-color</title>
  <g id="HWkeZ3">
    <g>
      <path d="M253.84,198.15C247.13,204.7,241,210.58,235,216.57q-30.8,30.74-61.48,61.54c-8.69,8.77-18.91,12.41-30.86,9.21A30,30,0,0,1,129,237.16c13.76-14.12,27.78-28,41.78-41.87,14.56-14.44,14.7-32.94.19-47.46-14-14-28.13-28-42.08-42.12a29.74,29.74,0,0,1-6-33.17,30.22,30.22,0,0,1,28.57-18,29.84,29.84,0,0,1,20.45,8.95c13.4,13.41,26.14,27.48,39.51,40.92,1,1,1.66,1.66,3.21,3.18,1.94-1.77,3-2.63,3.91-3.57q39-39,78-78c11.9-11.87,29.89-12.78,42.1-2.25,13.6,11.74,14.3,32.06,1.41,45.06-13.61,13.74-27.34,27.36-41,41-14.94,14.93-15,32.64-.05,47.62,13.75,13.78,27.64,27.42,41.26,41.32,16.45,16.78,9.49,44.29-12.87,50.87-11.61,3.43-21.92.5-30.49-7.95-13.14-12.95-26.12-26.05-39.15-39.12C256.49,201.35,255.4,199.94,253.84,198.15Z" style="fill: #29abe2"/>
      <path d="M350.08,89.89a8.17,8.17,0,0,1,6.85-.34,12.13,12.13,0,0,1,7,6.47c1.92,4.67,1.26,9-2.26,12.71-5.12,5.31-10.4,10.46-15.61,15.69-5.92,5.94-6,12.46-.15,18.42,4.9,5,9.92,9.87,14.83,14.85,5.34,5.43,5.59,12.48.7,17.25s-11.65,4.33-17-.95q-15.69-15.62-31.3-31.3c-5.72-5.75-5.65-12.3.11-18.07,10.43-10.45,20.78-21,31.38-31.23A31.18,31.18,0,0,1,350.08,89.89Z" style="fill: #19b24b"/>
      <path d="M162,166.87a10.55,10.55,0,0,1-.09,9.63,13.83,13.83,0,0,1-2.26,3.33c-10.54,10.85-21.31,21.49-32,32.15-4.81,4.78-11.31,5-16,.6s-4.83-11-.13-16c5-5.38,10.39-10.47,15.58-15.7,5.93-6,6-12.72,0-18.74-5-5.08-10.08-10-15.09-15.12s-5.23-11.44-.59-16.25c4.35-4.52,11.42-4.4,16.31.45,10.74,10.66,21.49,21.3,32,32.18A14.11,14.11,0,0,1,162,166.87Z" style="fill: #19b24b"/>
    </g>
  </g>
  <text transform="translate(421.14 180.74)" style="font-size: 142.32962036132812px;fill: #29abe2;font-family: SFCompactRounded-Medium, SF Compact Rounded;font-weight: 500">Xp<tspan x="162.48" y="0" style="letter-spacing: -0.001461451326659458em">r</tspan><tspan x="213.29" y="0">ess </tspan><tspan x="458.82" y="0" style="fill: #333">money</tspan></text>
`]
