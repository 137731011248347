import {
  CButton,
  CCard,
  CCardBody,
  CContainer,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useAuthStore } from '../../state/store'
import CIcon from '@coreui/icons-react'
import { logo } from '../../assets/brand/logo'
import { cilLockLocked, cilUser } from '@coreui/icons'

const Login = () => {
  const [login, loading] = useAuthStore((state) => [state.login, state.loading])

  const validationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required('Username is required'),
      password: Yup.string()
        .min(3, `Password has to be at least ${3} characters!`)
        // .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{3,}/, 'Password must contain: numbers, uppercase and lowercase letters\n')
        .required('Password is required'),
    })
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values: any) => {
      login(values)
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  })

  return (
    <CContainer>
      <CRow className="justify-content-center">
        <CCol md="5">
          <div className="text-center mt-5">
            <Link to="/">
              <CIcon icon={logo} height={72} title="Logo" />
            </Link>
          </div>
          <CCard className="bg-white mt-4">
            <CCardBody className="text-center">
              <CForm onSubmit={formik.handleSubmit}>
                <h1>Login</h1>
                <p className="text-muted">Sign In to your account</p>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    type="text"
                    size="lg"
                    name="username"
                    placeholder="Username"
                    autoComplete="username"
                    invalid={formik.touched.username && !!formik.errors.username}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.username}
                  />
                  <CFormFeedback invalid>{formik.errors.username}</CFormFeedback>
                </CInputGroup>

                <CInputGroup className="mb-4">
                  <CInputGroupText>
                    <CIcon icon={cilLockLocked} />
                  </CInputGroupText>
                  <CFormInput
                    type="password"
                    size="lg"
                    name="password"
                    placeholder="Password"
                    invalid={formik.touched.password && !!formik.errors.password}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <CFormFeedback invalid>{formik.errors.password}</CFormFeedback>
                </CInputGroup>

                <CButton
                  type="submit"
                  color="primary"
                  size="lg"
                  className="w-100"
                  disabled={!formik.isValid || loading}
                >
                  Login
                </CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default Login
