import create, { GetState, SetState } from 'zustand'
import { persist } from 'zustand/middleware'
import { AuthState, AuthStore } from './Auth.store'
interface MainAppState {
  sidebarShow: boolean | undefined
  unfoldable: boolean | undefined
  asideShow: boolean
  theme: string
  setShowSidebar: (value: boolean | undefined) => void
  setUnfoldable: (value: boolean | undefined) => void
  setAsideShow: (value: boolean) => void
  setTheme: (value: string) => void
}

const mainGlobalState: any = {
  theme: 'default',
}

const MainAppStore = (set: SetState<MainAppState>, _: GetState<MainAppState>) => ({
  ...mainGlobalState,
  setShowSidebar: async (value: boolean | undefined) => {
    set({ sidebarShow: value })
  },
  setUnfoldable: async (value: boolean | undefined) => {
    set({ unfoldable: value })
  },
  setAsideShow: async (value: boolean) => {
    set({ sidebarShow: value })
  },
  setTheme: async (value: string) => {
    set({ theme: value })
  },
})

const useAppStore = create<MainAppState>(MainAppStore)

export const useAuthStore = create<AuthState>(
  persist(AuthStore, { name: 'auth-store', getStorage: () => sessionStorage }),
)

export default useAppStore
