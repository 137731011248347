import { SetState } from 'zustand'
import { ROUTES } from '../constants/routes'
import APIProvider from '../utils/APIProvider'
import { handleAxiosError } from '../utils/error'
import { AuthScope } from './Hook.state'

export type AuthState = {
  login: (values: { email: string; password: string }) => Promise<void>
  getMe: () => Promise<void>
  loading: boolean
  token: string | null
  authUser: any
  currentAgent: any
  authScope: AuthScope
  logout: (navigate: any) => Promise<void>
}

const defaultState: any = {
  loading: false,
  token: null,
  authUser: null,
  authScope: AuthScope.AGENT,
  currentAgent: null,
}

export const AuthStore = (set: SetState<AuthState>) => ({
  ...defaultState,
  login: async (values: { email: string; password: string }) => {
    set({ loading: true })
    //console.log("values", values)
    try {
      const res = await APIProvider.login(values)
      const { user, token } = res.data
      set({
        loading: false,
        token: token,
        authUser: user,
        authScope: user.isAdmin && !user.agent ? AuthScope.AGENCY : AuthScope.AGENT,
      })
      localStorage.setItem('token', res.data.token)

      if (res.data && res.data.user && res.data.user.isAdmin) {
        window.location.href = ROUTES.dashboard
      } else {
        window.location.href = ROUTES.agentProfile
      }
    } catch (error) {
      set({ loading: false })
      handleAxiosError(error)
    }
  },
  getMe: async () => {
    set({ loading: true })
    try {
      const res = await APIProvider.get(`/users/me`)
      set({
        loading: false,
        authUser: res.data,
        authScope: res.data.isAdmin && !res.data.agent ? AuthScope.AGENCY : AuthScope.AGENT,
      })
    } catch (error) {
      set({ loading: false })
      handleAxiosError(error)
    }
  },
  logout: (navigate: any) => {
    console.log('AuthStore - logout')
    localStorage.clear()
    set(
      {
        token: null,
        authUser: null,
        currentAgent: null,
        authScope: AuthScope.NONE,
      },
      true,
    )
    window.location.href = ROUTES.login
  },
})
