export const ROUTES = {
  home: "/",
  login: "/login",
  dashboard: "/dashboard",

  users: "/users",
  userDetail: "/users/:id",

  agents: "/agents",
  agentDetail: "/agents/:id",

  agentProfile: "/home",

  transactions: "/transactions",
  expenses: "/expenses",
  transactionDetail: "/transactions/:id",

  pricegrids: "/pricegrids",
  pricegridDetail: "/pricegrids/:id",

  products: "/products",
  productDetail: "/products/:id",

  clients: "/clients",
  clientDetail: "/clients/:id",

  newOrder: "/orders/new",
  agentReceive: "/agent/receive",
  agentReceiveDetail: "/agent/receive/:id",

  orders: "/orders",
  ordersDetail: "/orders/:id",
  ordersReceive: "/orders/:id/receive",

  institutions: "/institutions",
  institutionDetail: "/institutions/:id",

  orderReport: "/reports/orders",
  payoutReport: "/reports/payouts",
  depositReport: "/reports/deposits",
  provisionReport: "/reports/provisions",
  outstandingReport: "/reports/outstanding",
  expenseReport: "/reports/expenses",

  rates: "/rates",
};
