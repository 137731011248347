import { PureComponent } from 'react';
import {
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilMagnifyingGlass } from '@coreui/icons'

interface IProps {}

class Page404 extends PureComponent<IProps, {}> {
  render() {
    return (
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 me-4">404</h1>
              <h4 className="pt-3">Oops! You{"'"}re lost.</h4>
              <p className="text-muted float-left">The page you are looking for was not found.</p>
            </div>
            <CInputGroup className="input-prepend">
              <CInputGroupText>
                <CIcon icon={cilMagnifyingGlass} />
              </CInputGroupText>
              <CFormInput size={'lg'} type="text" placeholder="What are you looking for?" />
              <CInputGroupText>
                <CButton color="primary">Search</CButton>
              </CInputGroupText>
            </CInputGroup>
          </CCol>
        </CRow>
      </CContainer>
    )
  }
}

export default Page404
