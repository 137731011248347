import toast from 'react-hot-toast'
import i18n from 'i18next'

const toastOptions: any = {
  duration: 3000,
  position: 'top-center',
}

export const handleAxiosError = async (error: any) => {
  if (
    (error.response?.status === 401 || error.response?.status === 403) &&
    window.location.pathname !== '/login'
  ) {
    toast.error('Unautorized access')
    //localStorage.clear();
    //window.location.replace("/login");
    return
  }

  toast.error(
    error?.response?.data?.message ? i18n.t(error.response.data.message) : i18n.t(error.message),
    toastOptions,
  )

  throw Error
}

export const successMessage = async (message: string) => {
  toast.success(i18n.t(message), toastOptions)
}

export const errorMessage = async (message: string) => {
  toast.success(i18n.t(message), toastOptions)
}

export const loading = async (message: string = 'loading pleae wait...') => {
  toast.loading(i18n.t(message), toastOptions)
}
export const dimiss = async () => {
  toast.dismiss()
}
