import { createIntl, createIntlCache } from 'react-intl'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import APIProvider from '../utils/APIProvider'

import { handleAxiosError, successMessage } from '../utils/error'
import { useAuthStore } from './store'

export enum RessourceType {
  AGENT = 'agents',
  USER = 'users',
  CLIENT = 'clients',
  RECEIVER = 'receivers',
  INSTITUTION = 'institutions',
  TRANSACTION = 'transactions',
  ORDER = 'orders',
  PRODUCT = 'products',
  PRICEGRID = 'priceGrids',
  REPORT = 'reports',
  FILE = 'files',
}

export interface FeeQuery {
  sourceCountry?: string | null
  destinationCountry?: string | null
  amount?: number
  currency?: string | null
  paymentMethod?: string | null
}

export enum AuthScope {
  NONE = '',
  AGENT = 'agent',
  AGENCY = 'agency',
}

export enum UserGroup {
  ADMIN = 'admin',
  AGENT = 'agent',
}

export enum AuthRoleLevel {
  SUPERADMIN = 1000,
  ADMIN = 100,
  MANAGER = 50,
  STAFF = 10,
}
export enum AuthRole {
  ADMIN = 'admin',
  MANAGER = 'manager',
  STAFF = 'staff',
  CLIENT = 'client',
}

export const userRolesTypeOptions = [
  { value: AuthRole.MANAGER, label: 'Manager' },
  { value: AuthRole.STAFF, label: 'Staff' },
]

export const agencyUserRolesTypeOptions = [
  { value: AuthRole.MANAGER, label: 'Manager' },
  { value: AuthRole.STAFF, label: 'Staff' },
]

export const paymentMethodOptions = [
  { value: '', label: 'None' },
  { value: 'cash', label: 'Cash' },
  { value: 'check', label: 'Check' },
  { value: 'wire', label: 'E-Transfer' },
  { value: 'bank', label: 'Bank Deposit' },
  { value: 'money_order', label: 'Money Order' },
]

export const orderTypeOptions = [
  { value: 'deposit', label: 'Agent Deposit' },
  { value: 'provision', label: 'Agent Provisioning' },
]

export const orderStatusOptions = [
  { value: 'created', label: 'Unprocessed' },
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
  { value: 'canceled', label: 'Canceled' },
]

export const orderStatusFilterOptions = [
  { value: '', label: 'All statuses' },
  { value: 'created', label: 'Unprocessed' },
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
  { value: 'canceled', label: 'Canceled' },
]

export const currencyOptions = [
  { value: 'USD', label: 'United States Dollar' },
  { value: 'GNF', label: 'Guinean Franc' },
  { value: 'XOF', label: 'West African CFA' },
]

export const overdraftOptions = [
  { value: 0, label: 'No Overdraft' },
  { value: 10, label: '10% over balance' },
  { value: 20, label: '20% over balance' },
  { value: 30, label: '30% over balance' },
  { value: 40, label: '40% over balance' },
  { value: 50, label: '50% over balance' },
]

export enum OrderStatus {
  CREATED = 'created', //created and never processed
  PENDING = 'pending', //pending payment completion or has faile or partial payments
  COMPLETED = 'completed', //fully paid
  CANCELED = 'canceled', //transaction is canceled
}
export interface FeeQuery {
  sourceCountry?: string | null
  destinationCountry?: string | null
  amount?: number
  currency?: string | null
  paymentMethod?: string | null
}

export const matrixRowTypeOptions = [
  { value: 'fixed', label: 'Fixed' },
  { value: 'percent', label: 'Percentage' },
  { value: 'percent_base', label: 'Base + Percentage' },
]

export enum TransactionType {
  ALL = 'all',
  AGENCY = 'agency',
  AGENT = 'agent',
  EXPENSE = 'expense',
}

export enum AgentTransactionType {
  ALL = 'all',
  PROVISION = 'provision',
  DEPOSIT = 'deposit',
  EXPENSE = 'expense',
}

export enum ExpenseTransactionEntryType {
  ALL = 'all',
  PAYROLL = 'payroll',
  GENERAL = 'general',
  OPERATION = 'operation',
  OTHER = 'other',
}

export enum TransactionStatus {
  CREATED = 'created', //created and never processed
  PENDING = 'pending', //pending payment completion or has faile or partial payments
  COMPLETED = 'completed', //fully paid
  CANCELED = 'canceled', //transaction is canceled
}

export const idTypeOptions = [
  { value: 'national_id', label: 'National ID Card' },
  { value: 'passport', label: 'Passport' },
  { value: 'drivers_license', label: "Driver's license" },
  { value: 'other', label: 'Other' },
]

export const agentTransactionTypeOptions = [
  { value: AgentTransactionType.DEPOSIT, label: 'Agent Deposit' },
  { value: AgentTransactionType.PROVISION, label: 'Provisioning (wire)' },
]

export const agentTransactionTypeFilterOptions = [
  { value: '', label: 'All Types' },
  ...agentTransactionTypeOptions,
]

export const expenseTransactionTypeOptions = [
  { value: ExpenseTransactionEntryType.PAYROLL, label: 'Payroll' },
  { value: ExpenseTransactionEntryType.GENERAL, label: 'General' },
  { value: ExpenseTransactionEntryType.OPERATION, label: 'Operation' },
  { value: ExpenseTransactionEntryType.OTHER, label: 'Other' },
]
export const expenseTransactionTypeFilterOptions = [
  { value: '', label: 'All Types' },
  ...expenseTransactionTypeOptions,
]

export const transactionStatusOptions = [
  { value: TransactionStatus.CREATED, label: 'Unprocessed' },
  { value: TransactionStatus.PENDING, label: 'Pending' },
  { value: TransactionStatus.COMPLETED, label: 'Completed' },
  { value: TransactionStatus.CANCELED, label: 'Canceled' },
]

export const originOptions = [
  { value: '', label: 'Origin' },
  { value: 'GN', label: 'Guinea' },
  { value: 'US', label: 'United States' },
]
export const destinationOptions = [
  { value: '', label: 'Destination' },
  { value: 'GN', label: 'Guinea' },
  { value: 'US', label: 'United States' },
]

export const transactionStatusFilterOptions = [
  { value: '', label: 'All statuses' },
  ...transactionStatusOptions,
]

const cache = createIntlCache()
export const intl = createIntl(
  {
    locale: 'fr-FR',
    messages: {},
  },
  cache,
)

const handleRedirect = (navigate: any, path: string, id?: string) => {
  if (id && path.includes(':id')) {
    path = path.replace(':id', id)
  }
  navigate(path)
}

export const useAuthtUser = () => {
  const authUser = useAuthStore((state) => state.authUser)
  return authUser
}

export const useCurrentAgent = () => {
  const authUser = useAuthStore((state) => state.authUser)
  const { data } = useFetchById(RessourceType.AGENT, 'me', {
    enabled: authUser.isAgent,
  })
  return data
}

const apiGet = async (ressource: RessourceType, params: QueryParams = {}) => {
  const res = await APIProvider.get(
    `/${ressource}${params.path ? `/${params.path}` : ''}`,
    params.filter,
  )
  return res.data
}

export interface QueryParams {
  filter?: object | undefined
  path?: string | undefined
}

export const useFetch = (ressource: RessourceType, params?: any, options?: any) => {
  return useQuery([ressource, params], () => apiGet(ressource, params), {
    ...(options && { ...options }),
    keepPreviousData: true,
    onError: (error) => {
      handleAxiosError(error)
    },
  })
}

export const useFetchById = (ressource: RessourceType, id: string | undefined, options?: any) => {
  return useQuery(
    [ressource, id],
    async () => {
      if (!id) return null
      const res = await APIProvider.get(`/${ressource}/${id}`)
      return res.data
    },
    {
      ...(options && { ...options }),
      keepPreviousData: true,
      onError: (error) => {
        handleAxiosError(error)
      },
    },
  )
}

export const useCreate = (ressource: RessourceType, redirectPath?: string) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation<any>(
    async (data: any) => {
      const res = await APIProvider.post(`/${ressource}`, data)
      return res.data
    },
    {
      onSuccess: (data: any) => {
        queryClient.invalidateQueries(ressource)
        successMessage(`${ressource} created successfully`)
        if (redirectPath) handleRedirect(navigate, redirectPath, data.id)

        if (ressource === RessourceType.TRANSACTION || ressource === RessourceType.ORDER) {
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === RessourceType.AGENT && query.queryKey[1] === 'me',
          })
        }
      },
      onError: (error) => {
        handleAxiosError(error)
      },
    },
  )
}

export const useAction = (ressource: RessourceType, action: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ id, ...data }: any) => {
      const res = await APIProvider.post(`/${ressource}/${id}/${action}`, data)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ressource)
        successMessage(`${ressource} updated successfully`)

        if (ressource === RessourceType.TRANSACTION || ressource === RessourceType.ORDER) {
          queryClient.invalidateQueries({
            predicate: (query) =>
              query.queryKey[0] === RessourceType.AGENT && query.queryKey[1] === 'me',
          })
        }
      },
      onError: (error) => {
        handleAxiosError(error)
      },
    },
  )
}

export const useUpdate = (ressource: RessourceType) => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ id, ...data }: any) => {
      const res = await APIProvider.update(`/${ressource}/${id}`, data)
      return res.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ressource)
        successMessage(`${ressource} updated successfully`)
      },
      onError: (error) => {
        handleAxiosError(error)
      },
    },
  )
}

export const useDelete = (ressource: RessourceType, redirectPath?: string) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    async (id: string) => {
      const res = await APIProvider.delete(`/${ressource}/${id}`)
      return res
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ressource)
        successMessage(`${ressource} deleted successfully`)
        if (redirectPath) handleRedirect(navigate, redirectPath)
      },
      onError: (error) => {
        handleAxiosError(error)
      },
    },
  )
}
